import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Share from 'components/elements/Share'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

const Video = styled.div`
  padding-top: 56%;
  position: relative;
  margin-top: -100px;
  border-radius: 30px !important;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    border-radius: 30px !important;
  }

  @media screen and (max-width: 991px) {
    margin-top: 0px;
  }
`

const Content = styled(ParseContent)`
  & h2 {
    @media (min-width: 992px) {
      font-size: 50px;
    }
  }

  & h3 {
    @media (min-width: 992px) {
      font-size: 30px;
    }
  }

  & ol {
    @media (max-width: 575px) {
      padding-left: 1rem !important;
    }
  }
`

const Blog: React.FC<BlogProps> = ({ fields, location }) => (
  <section>
    <div className="container py-xl-5 py-3">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-10 pb-lg-5 pb-3">
          {!!fields.video && (
            <Video className="mb-lg-5 mb-3">
              <iframe
                width="100%"
                height="100%"
                src={fields.video}
                title="player"
                frameBorder="0"
                allow="gyroscope;"
                allowFullScreen
              />
            </Video>
          )}
          {fields.share === 'yes-above' && (
            <div>
              <Share heading="Share:" location={location} title="" body="" />
            </div>
          )}
          <Content content={fields.description || ''} />
          {fields.share === 'yes-below' && (
            <div>
              <Share heading="Share:" location={location} title="" body="" />
            </div>
          )}
        </div>
      </div>
    </div>
  </section>
)

export default Blog
