/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface GreyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const GreyWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
`

const Grey: React.FC<GreyProps> = ({ fields }) => (
  <section>
    <GreyWrapper className="py-lg-3">
      <div className="container py-lg-5 py-3">
        <div
          className={`row position-relative justify-content-${
            fields.alignment || 'center'
          }`}
        >
          {fields.descriptions?.map((desc, index) => (
            <div
              key={index}
              className={`col-lg-${desc?.width} py-lg-0 py-3 d-flex align-items-center`}
            >
              <ParseContent content={desc?.description || ''} />
            </div>
          ))}
        </div>
      </div>
    </GreyWrapper>
  </section>
)

export default Grey
