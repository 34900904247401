import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Content = styled(ParseContent)`
  & h2 {
    padding-bottom: 0 !important;
    font-size: 40px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  width: 83px;
  height: 83px;
`

interface TextCaseProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextCase: React.FC<TextCaseProps> = ({ fields }) => (
  <section id={fields.webid}>
    <div className="container py-lg-5 py-3">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="d-flex align-items-center mb-4 pb-3">
            <StyledPlaatjie image={fields.icon} alt="" className="me-4" />
            <Content content={fields.title || ''} />
          </div>
          <ParseContent content={fields.description || ''} />
        </div>
      </div>
    </div>
  </section>
)

export default TextCase
