import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section id={fields.webid}>
    <div className="container py-lg-5 py-3">
      <ParseContent content={fields.description || ''} />
    </div>
  </section>
)

export default Text
